body {
    /* font-family: "Montserrat", sans-serif; */
    font-family: "Roboto Condensed", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
}

a {
    text-decoration: none;
}

.chip {
    background: gray;
    color: white;
    border: none;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
}

.chip-list {
    white-space: nowrap;
}

.chip--selected {
    background: black;
}

.table {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border-spacing: 0;
}

.table td {
    padding: 5px 10px;
    line-height: 1.3;
}

.table tr td:first-child {
    padding-left: 0;
}

.table tr:nth-last-of-type(2) td {
    padding-bottom: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    margin: 0 auto;
}

h2 {
    font-size: 180%;
    text-transform: uppercase;
}

td.collapsed {
    width: 0.00000001%;
}

.header-line {
    opacity: 0.3;
}

.header-line td {
    padding: 0 10px;
}

.strava-logo {
    max-width: 150px;
}

.strava-connect {
    max-width: 150px;
    cursor: pointer;
}

li {
    line-height: 110%;
    padding-bottom: 8px;
}

ul {
    padding-inline-start: 24px;
}

.grid-container {
    display: grid;
    justify-items: end;
    column-gap: 8px;
}

.grid-item {
    font-size: 14px;
    white-space: nowrap;
}

.grid-item.time-and-climb {
    opacity: 0.3;
    grid-column: 1;
    grid-row: 2;
    text-align: right;
}

.grid-item.time-and-climb span {
    padding: 0 3px;
}

.grid-item.time-and-climb span:last-child {
    padding-right: 0;
}

.grid-item.score {
    grid-column: 1 / 2;
    grid-row: 1;
    text-align: center;
}

.sum td {
    border-top: 2px solid #c5c5c5;
    padding-top: 20px;
    white-space: nowrap;
}

.badge {
    aspect-ratio: 1;
    display: inline-grid;
    place-items: center;
    width: 20px;
    background: rgb(252, 191, 0);
    border-radius: 50%;
}

.badge svg {
    max-height: 13px;
    max-width: 13px;
}

.badge path {
    color: white;
}

.badgeInfo {
    opacity: 0.5;
}

.table-left-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
}

.table-name-container {
    display: grid;
    grid-auto-flow: row;
}

.table-badge-container {
    margin-top: 2px;
    margin-left: 4px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-items: flex-start;
}
